/* global require $ */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// The relative path is important, because otherwise it will use the image folder
// from the "project" and not from cam_backend which is the fallback
require.context('../images', true)
// console.log(images)
// const imagePath = (name) => images(name, true)

// Styles
import 'styles/cam_backend/application'
import 'styles/cam_backend/forms'

// Libraries
import 'lodash'
import 'jquery'
import 'jquery-ui'
import 'jquery-ui/ui/widgets/sortable'

require('expose-loader?exposes=PDFObject!pdfobject')
require('../scripts/vendor/modernizr-custom')
require('expose-loader?exposes=moment!moment')
require('../scripts/vendor/momentjs/locale/de-ch')
require('../scripts/vendor/momentjs/locale/fr-ch')
require('@rails/ujs').start()
require('turbolinks').start()

// For the material_dashboard_pro ui
require('../scripts/cam_backend/ui/bootstrap.min')
require('../scripts/cam_backend/ui/bootstrap-notify')
require('../scripts/cam_backend/ui/material.min')
require('../scripts/cam_backend/ui/jquery.select-bootstrap')
require('../scripts/cam_backend/ui/bootstrap-datetimepicker')
require('../scripts/cam_backend/ui/nouislider.min')
require('../scripts/cam_backend/material-dashboard-custom-setup')

// Load cam_backend, specially for the templates and document_modal lightbox used for .files-collection elements
require('../scripts/cam_backend/cam_backend')
require('../scripts/cam_backend/behaviours/textarea_resize')
require('../scripts/cam_backend/behaviours/tooltip')
require('../scripts/cam_backend/behaviours/picture_upload')
require('../scripts/cam_backend/behaviours/if_else_form')

window.$(document).on('click', ':checkbox ~ label[for], :radio ~ label[for]', function(e) {
  e.preventDefault()
  return $('[id="' + this.getAttribute('for') + '"]:visible').click()
})
